<template>
  <section class=''>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>종목명</label>
      <div class=''>{{ selectedIpo.name }}</div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>주관사</label>
      <div class=''>{{ brokerNames }}</div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>공모가밴드</label>
      <div class='w-32'>{{ estimatedPrice }}</div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>확정공모가</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(allocatedPrice)'
        @change='changePrice' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>원</span>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2 whitespace-no-wrap'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>공모 후 시총</label>
      <div class='w-32 text-right pr-4'>{{ formattedFixedOfferingAmount }}</div>
      <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>공모 전 발행주식총수</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(beforeQuantity)'
        @change='changeBefore' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>신규공모</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(totalQuantity)'
        @change='changeTotal' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(totalAmount) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>구주매출</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(oldStocksQuantity)'
        @change='changeOldStocksQuantity' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(oldStocksSales) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>기관배정주식수</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(companyQuantity)'
        @change='changeCompany' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(companyAmount) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>벤처펀드 해당분</label>
      <div class='w-32 text-right pr-4'>{{ numberStyle(ventureQuantity) }}</div>
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(ventureAmount) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 text-sm lg:text-base px-4 py-3 ml-4 whitespace-no-wrap'>벤처여부</label>
      <select
        v-model='is_venture'
        class='form-select'
        >
        <option v-for='option in options'
                :key='option.value'
                :value='option.value'>
           {{option.text}}
        </option>
      </select>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>하이일드 해당분</label>
      <div class='w-32 text-right pr-4'>{{ numberStyle(highYieldQuantity) }}</div>
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(highYieldAmount) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>고유 해당분</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(proprietaryQuantity)'
        @change='changeProprietary' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(proprietaryAmount) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 text-sm lg:text-base px-4 py-3 ml-4 whitespace-no-wrap'>고유확약여부</label>
      <select
        v-model='selectedProprietaryQuantityPeriod'
        class='form-select'
        >
        <option v-for='option in timePeriods'
                :key='`selected-option-${option}`'
                :value='option'>
           {{option}}
        </option>
      </select>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>캐피탈 해당분</label>
      <input class='form-input text-right w-32'
        v-bind:value='numberStyle(capitalQuantity)'
        @change='changeCapital' />
      <span class='text-sm text-gray-600 opacity-80 font-light'>주</span>
      <div class='w-10 text-right whitespace-no-wrap'>
        {{ numberStyle(capitalAmount) }}
        <span class='text-sm text-gray-600 opacity-80 font-light'>억</span>
      </div>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 text-sm lg:text-base px-4 py-3 ml-4 whitespace-no-wrap'>캐피탈확약여부</label>
      <select
        v-model='selectedCapitalQuantityPeriod'
        class='form-select'
        >
        <option v-for='option in timePeriods'
        :key='`selected-option-${option}`'
                :value='option'>
           {{option}}
        </option>
      </select>
    </div>
    <div class='my-1 flex flex-row justify-start items-center gap-x-2'>
      <label class='block flex-shrink-0 bg-gray-200 text-gray-700 w-40 lg:w-48 text-sm lg:text-base px-2 py-3'>수요예측 경쟁률</label>
      <div class='w-32 text-right pr-4'>{{ competitionRate }}</div>
    </div>
  </section>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'IpoAllocatedInformation',
  data () {
    return {
      ventureQty: -1,
      options: [
        { text: '여', value: true  },
        { text: '부', value: false },
      ],
      timePeriods: ['미확약', '15일확약', '1개월확약', '3개월확약', '6개월확약'],
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
      'selectedIpoId',
      'ipoSummaries',
    ]),
    ...mapState('brokers', [
      'brokers',
    ]),
    ...mapFields('ipos', [
      'selectedIpo.is_venture',
    ]),
    ...mapGetters('users', [
      'currentIpoDetailsName',
    ]),
    filteredBrokers () {
      return this.brokers && this.selectedIpo.broker_ids ? this.brokers.filter(broker => this.selectedIpo.broker_ids.includes(broker.id)) : []
    },
    brokerNames () {
      return this.filteredBrokers.map(broker => broker.name).join(',\n')
    },
    estimatedPrice () {
      return this.selectedIpo.details && this.selectedIpo.details['희망공모가액'] ? this.selectedIpo.details['희망공모가액'] : ''
    },
    allocatedPrice () {
      return this.selectedIpo.details && this.selectedIpo.details['확정공모가'] ? this.selectedIpo.details['확정공모가'] : 0
    },
    sharesOutstanding () {
      return this.selectedIpo.details && this.selectedIpo.details['총발행주식수'] ? this.selectedIpo.details['총발행주식수'] : 0
    },
    fixedOfferingAmount () {
      // 공모후 시총 = 확정공모가(수기입력) * (공모 전 발행주식총수(수기입력) + 신규공모(수기입력))
      return this.allocatedPrice * (this.beforeQuantity + this.totalQuantity)
    },
    formattedFixedOfferingAmount () {
      return this.fixedOfferingAmount > 0 ? `${this.numberStyle(Math.round(this.fixedOfferingAmount / 100000000, 0))}` : ''
    },
    competitionRate () {
      if (this.selectedIpo.details && this.selectedIpo.details['기관경쟁률'])
        return this.selectedIpo.details['기관경쟁률']
      else
        return ''
    },
    totalQuantity () {
      // 신규공모(컬럼명이 공모주식수에서 신규공모로 변경됨)
      return this.selectedIpo.details && this.selectedIpo.details['총공모주식수'] ? parseFloat(this.selectedIpo.details['총공모주식수']) : 0
    },
    oldStocksQuantity () {
      if (this.selectedIpo.details && this.selectedIpo.details['U_구주매출주식수']) {
        return this.selectedIpo.details['U_구주매출주식수']
      } else if (this.selectedIpo.details && this.selectedIpo.details['구주매출주식수']) {
        return this.selectedIpo.details['구주매출주식수']
      } else  {
        return 0
      }
    },
    companyQuantity () {
      return this.selectedIpo.details && this.selectedIpo.details['기관배정주식수'] ? this.selectedIpo.details['기관배정주식수'] : 0
    },
    beforeQuantity () {
      return this.selectedIpo.details && this.selectedIpo.details['공모전발행주식수'] ? this.selectedIpo.details['공모전발행주식수'] : 0
    },
    ventureQuantity () {
      // 공모주식수(totalQuantity) * 0.25
      return Math.trunc(this.totalQuantity * 0.25)
    },
    highYieldQuantity () {
      // 공모주식수(totalQuantity) * 0.01
      return Math.trunc(this.totalQuantity * 0.01)
    },
    proprietaryQuantity () {
      return this.selectedIpo.details &&
            this.selectedIpo.details[this.currentIpoDetailsName] &&
            this.selectedIpo.details[this.currentIpoDetailsName]['고유배정주식수'] ? this.selectedIpo.details[this.currentIpoDetailsName]['고유배정주식수'] : 0
    },
    capitalQuantity () {
      return this.selectedIpo.details &&
            this.selectedIpo.details[this.currentIpoDetailsName] &&
            this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈배정주식수'] ? this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈배정주식수'] : 0
    },
    selectedProprietaryQuantityPeriod: {
      get () {
        return this.selectedIpo.details &&
              this.selectedIpo.details[this.currentIpoDetailsName] &&
              this.selectedIpo.details[this.currentIpoDetailsName]['고유확약여부'] ? this.selectedIpo.details[this.currentIpoDetailsName]['고유확약여부'] : ''
      },
      set (value) {
        let details = {
          id: this.selectedIpo.id,
          currentIpoDetailsName: this.currentIpoDetailsName,
          key: '고유확약여부',
          type: 'text',
          value: value
        }
        this.updateIpoDetail( details ).then(() => {
        })
      }
    },
    selectedCapitalQuantityPeriod: {
      get () {
        return this.selectedIpo.details &&
              this.selectedIpo.details[this.currentIpoDetailsName] &&
              this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈확약여부'] ? this.selectedIpo.details[this.currentIpoDetailsName]['캐피탈확약여부'] : ''
      },
      set (value) {
        let details = {
          id: this.selectedIpo.id,
          currentIpoDetailsName: this.currentIpoDetailsName,
          key: '캐피탈확약여부',
          type: 'text',
          value: value
        }
        this.updateIpoDetail( details ).then(() => {
        })
      }
    },
    totalAmount () {
      return (this.totalQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
    oldStocksSales () {
      return (this.oldStocksQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
    companyAmount () {
      return (this.companyQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
    ventureAmount () {
      return (this.ventureQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
    highYieldAmount () {
      return (this.highYieldQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
    proprietaryAmount () {
      return (this.proprietaryQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
    capitalAmount () {
      return (this.capitalQuantity * this.allocatedPrice / 100000000).toFixed(0)
    },
  },
  methods: {
    ...mapActions('ipos', [
      'updateIpoDetail',
      'updateIpoIsVenture',
    ]),
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    changeOldStocksQuantity () {
      let details = {
        id: this.selectedIpo.id,
        key: 'U_구주매출주식수',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    changeCompany () {
      let details = {
        id: this.selectedIpo.id,
        key: '기관배정주식수',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    changeProprietary () {
      let details = {
        id: this.selectedIpo.id,
        currentIpoDetailsName: this.currentIpoDetailsName,
        key: '고유배정주식수',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    changeCapital () {
      let details = {
        id: this.selectedIpo.id,
        currentIpoDetailsName: this.currentIpoDetailsName,
        key: '캐피탈배정주식수',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    changePrice () {
      let details = {
        id: this.selectedIpo.id,
        key: '확정공모가',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    changeTotal () {
      let details = {
        id: this.selectedIpo.id,
        key: '총공모주식수',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    changeBefore () {
      let details = {
        id: this.selectedIpo.id,
        key: '공모전발행주식수',
        type: 'number',
        value: event.target.value
      }
      this.updateIpoDetail( details ).then(() => {
      })
    },
    updateIsVenture () {
      this.updateIpoIsVenture( {ipo_id: this.selectedIpo.id, is_venture: this.is_venture} )
    },
  },
}
</script>
